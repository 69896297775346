import React from "react";
import "./index.scss";
import classNames from "classnames";

export const Input = ({
  className,
  label = "",
  value,
  setValue,
  Type = "input",
  required = false,
  type,
  options,
  placeholder,
  children,
  checked,
  defaultChecked,
  disabled,
}) => {
  const baseClass = classNames(className, "Type");

  const handleOnChange = (e) => {
    const { files, value, checked } = e.target;
    let val = value;
    switch (type) {
      case "file":
        val = files[0];
        break;
      case "checkbox":
        val = checked;
        break;
      default:
        break;
    }
    setValue(val);
  };

  return (
    <label className={baseClass}>
      <div className={`${baseClass}Label`}>{label}</div>
      <div className={`${baseClass}BoxWrap`}>
        <Type
          className={`${baseClass}Box`}
          value={value}
          onChange={handleOnChange}
          required={required}
          defaultChecked={defaultChecked}
          checked={checked}
          type={type}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
        >
          {Type === "select"
            ? options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))
            : null}
        </Type>
        {children}
      </div>
    </label>
  );
};

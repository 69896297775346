import React from "react";
import "./index.scss";
import { Input } from "../../atoms/Input";
import { ButtonIcon } from "../../atoms/ButtonIcon";

export const Classifier = ({
  toggle,
  label,
  value,
  setValue,
  newValue,
  setNewValue,
  options,
}) => {
  const baseClass = "Classifier";
  return (
    <div className={baseClass}>
      <Input
        Type={!toggle ? "select" : undefined}
        label={label}
        value={toggle ? newValue : value}
        setValue={toggle ? setNewValue : setValue}
        options={options}
        required
      >
        {toggle && (
          <ButtonIcon
            className={`${baseClass}Return`}
            onClick={() => setValue("")}
            src="/icons/return.svg"
            alt="return"
          />
        )}
      </Input>
    </div>
  );
};

import React, { useState } from "react";
import classNames from "classnames";
import { useApp } from "../../../AppProvider";
import { useInventoryData } from "../../../DataProviders/InventoryDataProvider";
import { Search } from "../Search";
import {
  CONTACT,
  HOME,
  INVENTORY,
  OUR_STORY,
  SET,
} from "../../../util/Constants";
import "./index.scss";

export const Header = () => {
  const { totalValue } = useInventoryData();
  const { setOpenItemForm, setOpenSetForm, isAdmin } = useApp();
  const [openMenu, setOpenMenu] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const baseClass = "Header";

  const getAdd = () => {
    switch (window.location.pathname) {
      case INVENTORY:
        return "Item";
      case SET:
        return "Package";
      default:
        return "";
    }
  };
  const handleAddClick = () => {
    switch (window.location.pathname) {
      case INVENTORY:
        setOpenItemForm((prev) => !prev);
        break;
      case SET:
        setOpenSetForm((prev) => !prev);
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${baseClass}Wrapper`}>
      <div className={baseClass}>
        <div
          className={`${baseClass}Icon`}
          onClick={() => {
            setOpenSearch(false);
            setOpenMenu((prev) => !prev);
          }}
        >
          <img src="/icons/burger.svg" alt="burger" />
        </div>
        <div className={`${baseClass}Title`}>
          <div className={`${baseClass}Name`}>The Little Lights</div>
          <div className={`${baseClass}Caption`}>Illuminating your Dreams</div>
        </div>

        <div
          className={classNames(`${baseClass}Icon`, { Toggle: openSearch })}
          onClick={() => {
            setOpenMenu(false);
            setOpenSearch((prev) => !prev);
          }}
        >
          <img src="/icons/search.svg" alt="burger" />
        </div>
      </div>

      {(openSearch || openMenu) && (
        <div
          className={`${baseClass}Blocker`}
          onClick={() => {
            setOpenSearch(false);
            setOpenMenu(false);
          }}
        />
      )}
      <div
        className={classNames(`${baseClass}Slide`, "Nav", { Open: openMenu })}
      >
        <button
          className={classNames(`NavLink`, "Close")}
          onClick={(e) => {
            e.preventDefault();
            setOpenMenu((prev) => !prev);
          }}
        >
          &times;
        </button>
        <a className={`NavLink`} href={HOME}>
          Home
        </a>
        <a className={`NavLink`} href={INVENTORY}>
          Inventory
        </a>
        {isAdmin && (
          <a className={`NavLink`} href={SET}>
            Packages
          </a>
        )}
        <a className={`NavLink`} href={OUR_STORY}>
          Our Story
        </a>
        <a className={`NavLink`} href={CONTACT}>
          Contact
        </a>
        {isAdmin && (
          <>
            <button
              className={classNames(`NavLink`, "Add")}
              onClick={handleAddClick}
            >
              &#43; Add {getAdd()}
            </button>
            <button className={classNames(`NavLink`, "Total")}>
              Total: ${totalValue}
            </button>
          </>
        )}
      </div>

      <div
        className={classNames(`${baseClass}Slide`, "SearchBar", {
          Open: openSearch,
        })}
      >
        <Search />
      </div>
    </div>
  );
};

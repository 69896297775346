import React, { useCallback, useMemo } from "react";
import { useApp } from "../../../AppProvider";
import { useInventoryData } from "../../../DataProviders/InventoryDataProvider";
import { Cards } from "../Cards";
import "./index.scss";

export const Section = ({ set }) => {
  const { isAdmin } = useApp();
  const { allItems } = useInventoryData();
  const { name, items, price } = set;
  const baseClass = "Section";

  const getItems = useCallback(() => {
    return allItems?.filter((item) => items?.includes(item.id));
  }, [allItems, items]);

  const total = useMemo(() => {
    let total = 0;
    getItems().forEach((item) => {
      if (item.price) total += parseFloat(item.price);
    });
    return total;
  }, [getItems]);

  const discount = useMemo(() => {
    if (total) {
      return (((total - price) * 100) / price).toFixed(2);
    }
    return 0;
  }, [total, price]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className={baseClass}>
      <div className={`${baseClass}Header`}>
        <div className={`${baseClass}Name`}>{name}</div>

        <div className={`${baseClass}Price`}>
          <span className={`${baseClass}PriceTotal`}>
            <span className={`${baseClass}PriceTotalSlash`}></span>
            {`$${total}`}
          </span>
          <span className={`${baseClass}PriceValue`}>{`$${price}`}</span>
          <span
            className={`${baseClass}PriceDiscount`}
          >{`${discount}%OFF`}</span>
        </div>
      </div>
      {/*{!isAdmin && (*/}
      {/*  <div className={`${baseClass}Share`}>*/}
      {/*    <ButtonIcon*/}
      {/*      src={"/icons/share.svg"}*/}
      {/*      alt="share"*/}
      {/*      onClick={() => {*/}
      {/*        navigator.clipboard.writeText(*/}
      {/*          `${window.location.href}/${encodeURIComponent(set.id)}`,*/}
      {/*        );*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      <Cards items={getItems()} reduceMotion={isAdmin} />
    </div>
  );
};

import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

const AppContext = createContext(null);
export const useApp = () => useContext(AppContext);
export const AppProvider = ({ children }) => {
  const root = useRef();
  const [openItemForm, setOpenItemForm] = useState(false);
  const [openSetForm, setOpenSetForm] = useState(false);
  const [editItem, setEditItem] = useState("");
  const [editSet, setEditSet] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const isAdmin = false;

  const addItem = useCallback(
    (id) => {
      checkedItems.push(id);
      setCheckedItems([...checkedItems]);
    },
    [checkedItems],
  );

  const removeItem = useCallback(
    (id) => {
      setCheckedItems([...checkedItems.filter((itemId) => itemId !== id)]);
    },
    [checkedItems],
  );

  return (
    <AppContext.Provider
      value={{
        isAdmin,
        openItemForm,
        setOpenItemForm,
        editItem,
        setEditItem,
        openSetForm,
        setOpenSetForm,
        editSet,
        setEditSet,
        checkedItems,
        setCheckedItems,
        addItem,
        removeItem,
        root,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React from "react";
import "./index.scss";
import classNames from "classnames";

export const Modal = ({ className, open, children }) => {
  const baseClass = classNames(className, "Modal");

  if (!open) {
    return <></>;
  }

  return <div className={baseClass}>{children}</div>;
};

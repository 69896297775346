import React from "react";
import { useApp } from "../../AppProvider";
import { useInventoryData } from "../../DataProviders/InventoryDataProvider";
import { ActionBar } from "../../components/organism/ActionBar";
import { Cards } from "../../components/organism/Cards";
import "./index.scss";

export const Inventory = () => {
  const { isAdmin } = useApp();
  const { inv } = useInventoryData();
  const baseClass = "Inventory";

  return (
    <div className={baseClass}>
      <ActionBar />
      <Cards items={inv} reduceMotion={isAdmin} />
    </div>
  );
};

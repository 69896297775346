import React from "react";
import classNames from "classnames";
import { Button } from "../Button";
import "./index.scss";

export const ButtonIcon = ({ className, src, alt, ...props }) => {
  const baseClass = "ButtonIcon";

  return (
    <Button className={classNames(className, baseClass)} {...props}>
      {typeof src === "string" ? <img src={src} alt={alt} /> : src}
    </Button>
  );
};

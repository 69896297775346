export const CLOUD_NAME = "dibldgmvw";
export const UPLOAD_PRESET = "gkgc7xs5";
export const DB_NAME = "inventory";
export const MAX_QUANTITY = 50;
export const SELECT_DEFAULT = "All";

/* Routes */
export const HOME = "/";
export const INVENTORY = "/inventory";
export const SET = "/set";
export const OUR_STORY = "/our-story";
export const CONTACT = "/contact";

import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import classNames from "classnames";
import { gsap } from "gsap";
import { Input } from "../../atoms/Input";
import { useDevice } from "../../../util/useDevice";
import { useApp } from "../../../AppProvider";
import "./index.scss";

export const Card = forwardRef(
  ({ item, className, onClick, enableCheck, active }, ref) => {
    const {
      addItem,
      checkedItems,
      removeItem,
      isAdmin,
      setEditItem,
      setOpenItemForm,
      root,
    } = useApp();
    const { id, image, name, category, description, price, quantity, value } =
      item;
    const { isMobile, isDesktop } = useDevice();
    const docW = document.documentElement.clientWidth;
    const docH = document.documentElement.clientHeight;
    const baseClass = "Card";
    let card = useRef(null);
    const { x, y, width, height } = card.current?.getBoundingClientRect() || {};
    const wrapper = useRef(null);
    const nameRef = useRef(null);
    const hidden = useRef([]);
    const timeline = useRef(
      gsap
        .timeline({
          defaults: {
            ease: "power4.out",
          },
        })
        .add("start"),
    );

    const handleCheck = (checked) => {
      if (checked) {
        addItem(id);
      } else {
        removeItem(id);
      }
    };

    const handleUpdate = (item) => {
      setEditItem(item);
      setOpenItemForm(true);
    };

    useEffect(() => {
      if (!isDesktop) {
        if (active) {
          timeline.current.restart();
          timeline.current.play();
        } else timeline.current.revert();
      }
    }, [active, isDesktop]);

    useLayoutEffect(() => {
      let ctx = gsap.context(() => {
        const dX = docW / 2 - x - width / 2;
        const dY = docH / 2 - y - height / 2;
        timeline.current
          .to(
            card.current,
            {
              duration: 0.5,
              scale: isDesktop ? 1.5 : 2.2,
              zIndex: 100,
              x: !isDesktop
                ? docW / 2 > x
                  ? `+=${dX}`
                  : `-=${dX * -1}`
                : undefined,
              y: !isDesktop
                ? docH / 2 > y
                  ? `+=${dY}`
                  : `-=${dY * -1}`
                : undefined,
            },
            "start",
          )
          .to(
            wrapper.current,
            {
              duration: 0.5,
              position: "absolute",
              height: "max-content",
              padding: isMobile ? "5px" : "10px",
            },
            "start",
          )
          .to(
            nameRef.current,
            { duration: 0.3, fontSize: 12, textAlign: "left" },
            "start",
          )
          .to(
            hidden.current,
            {
              duration: 0.3,
              display: "inherit",
              opacity: 1,
            },
            "start",
          )
          .reverse();
      }, root);
      return () => ctx.revert();
    }, [root, docH, docW, x, y, width, height, isMobile, isDesktop]);

    return (
      <div
        ref={(el) => {
          card.current = el;
          ref(el);
        }}
        className={classNames(className, { desktop: isDesktop }, baseClass)}
        onClick={onClick}
        onMouseEnter={() => {
          isDesktop && timeline.current.play();
        }}
        onMouseLeave={() => {
          isDesktop && timeline.current.reverse();
        }}
      >
        {enableCheck && (
          <Input
            type="checkbox"
            checked={checkedItems.includes(id)}
            setValue={handleCheck}
          />
        )}
        <div ref={wrapper} className={`${baseClass}Wrapper`}>
          <div className={`${baseClass}Header`}>
            <div
              ref={(ref) => hidden.current.push(ref)}
              className={`${baseClass}Quantity Hidden`}
            >{`x${quantity}`}</div>
            {isAdmin && (
              <div
                ref={(ref) => hidden.current.push(ref)}
                className={`${baseClass}Update Hidden`}
                onClick={() => handleUpdate(item)}
              >
                <img src="/icons/gear.svg" alt="little lights gear" />
              </div>
            )}
          </div>
          <div className={`${baseClass}Image`}>
            <img src={image} alt={name} />
          </div>
          <div ref={nameRef} className={`${baseClass}Name`}>
            {name}
          </div>
          <div
            ref={(ref) => hidden.current.push(ref)}
            className={`${baseClass}Content Hidden`}
          >
            <div className={`${baseClass}Category`}>{category}</div>
            <div className={`${baseClass}Price`}>${price || ""}</div>
            {description && (
              <div className={`${baseClass}Description`}>{description}</div>
            )}
          </div>
        </div>
        {!value && isAdmin && (
          <div
            style={{
              position: "absolute",
              color: "red",
              top: "5px",
              left: "10px",
            }}
          >
            *
          </div>
        )}
      </div>
    );
  },
);

import React from "react";
import "./index.scss";

export const OurStory = () => {
  const baseClass = "OurStory";

  return (
    <div className={baseClass}>
      <h1>Our Story</h1>
    </div>
  );
};

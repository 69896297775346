import React from "react";
import { useParams } from "react-router-dom";
import { useSetData } from "../../DataProviders/SetDataProvider";
import { Section } from "../../components/organism/Section";
import "./index.scss";

export const Set = () => {
  const { collection } = useSetData();
  const { id } = useParams();
  const baseClass = "Set";

  if (!collection) return null;

  if (id)
    return (
      <div className={baseClass}>
        <Section set={collection[id]} />
      </div>
    );

  return (
    <div className={baseClass}>
      {Object.keys(collection).map((id) => (
        <Section key={id} set={collection[id]} />
      ))}
    </div>
  );
};

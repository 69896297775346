import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { remove as removeData, set } from "firebase/database";
import { useSearchParams } from "react-router-dom";
import { get, invRef, itemRef } from "../util/firebase";

const InventoryDataContext = createContext(null);
export const useInventoryData = () => useContext(InventoryDataContext);
export const InventoryDataProvider = ({ children }) => {
  const [allItems, setAllItems] = useState([]);
  const [inv, setInv] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderMap = new Map([
    ["Furniture", 0],
    ["Rugs", 1],
    ["Crockery", 2],
    ["Cutlery", 3],
    ["Cushions", 4],
    ["Textiles", 5],
    ["Nature", 6],
    ["Candle Decor", 7],
    ["General Decor", 8],
    ["Illumination", 9],
    ["Games", 10],
  ]);

  const categoryOptions = useMemo(
    () => [...new Set(allItems.map((item) => item["category"]))],
    [allItems],
  );

  /* DATA CRUD Operations */

  const getItems = useCallback(() => {
    get(invRef).then((data) => {
      const items = Object.keys(data)
        .map((key) => data[key])
        .filter((item) => !item.hide);
      setAllItems(items);
      setInv(sortByCategory(items));
    });
  }, []);

  const save = useCallback(
    async (item) => {
      let itemIdx = allItems.findIndex((t) => t.id === item.id);
      if (itemIdx < 0) {
        allItems.unshift(item);
        if (
          searchParams.get("category") === "All" ||
          searchParams.get("category") === undefined ||
          searchParams.get("category") === item.category
        ) {
          inv.unshift(item);
        }
      } else {
        allItems[itemIdx] = item;
      }
      await set(itemRef(item.id), item);

      itemIdx = inv.findIndex((t) => t.id === item.id);
      if (itemIdx >= 0) {
        inv[itemIdx] = item;
      }
      setInv(inv);
    },
    [allItems, inv, searchParams],
  );

  const remove = useCallback(
    async (id) => {
      await removeData(itemRef(id));

      const item = inv.find((i) => i.id === id);
      if (item) {
        const newInv = inv.filter((i) => i !== item);
        setInv(newInv);
      }
    },
    [inv],
  );

  // const updateInventory = async () => await set(invRef(), inv);

  useEffect(() => {
    getItems();
  }, [getItems]);

  /* Core functions */
  const searchItems = useCallback(
    (key) => {
      if (key) {
        setInv(
          sortByCategory(
            allItems.filter((item) => match(item, key.toLowerCase())),
          ),
        );
      } else {
        setInv(sortByCategory(allItems));
      }
    },
    [allItems],
  );

  const sortByCategory = (items) => [
    ...items.sort((a, b) => {
      const diff =
        orderMap.get(a["category"].toString()) -
        orderMap.get(b["category"].toString());
      if (diff === 0) {
        return a["name"].toString().localeCompare(b["name"].toString());
      }
      return diff;
    }),
  ];

  const sortItems = useCallback(
    (key, order) => {
      setInv([
        ...inv.sort(
          (a, b) =>
            a[key].toString().localeCompare(b[key].toString()) *
            (!order * 1 + order * -1),
        ),
      ]);
    },
    [inv],
  );

  const filterItems = useCallback(
    (key) => {
      if (categoryOptions.includes(key)) {
        setInv([...allItems.filter((item) => item["category"] === key)]);
      } else {
        setInv(sortByCategory(allItems));
      }
    },
    [allItems, categoryOptions],
  );

  const orderItems = useCallback(() => {
    setInv([...inv.reverse()]);
  }, [inv]);

  const totalValue = useMemo(() => {
    let sum = 0;
    inv.forEach((item) => {
      if (item.value !== "") {
        sum += parseFloat(item.value);
      }
    });
    return sum.toFixed(2);
  }, [inv]);

  /* Helper Functions */

  const match = (item, key) => {
    const { id, name, category, description, tags } = item;
    return (
      id.toString().toLowerCase().includes(key) ||
      name.toLowerCase().includes(key) ||
      category.toLowerCase().includes(key) ||
      description?.toLowerCase().includes(key) ||
      tags?.toLowerCase().includes(key)
    );
  };

  return (
    <InventoryDataContext.Provider
      value={{
        allItems,
        inv,
        save,
        remove,
        searchItems,
        filterItems,
        categoryOptions,
        orderItems,
        sortItems,
        totalValue,
      }}
    >
      {children}
    </InventoryDataContext.Provider>
  );
};

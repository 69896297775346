import React from "react";

export const OrderIcon = () => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path className="Up" fill="grey" d="M11 7h-6l3-4z" />
    <path className="Down" fill="grey" d="M5 9h6l-3 4z" />
  </svg>
);

export const CheckIcon = ({ check }) => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" fill="white" fillOpacity="0.01" />
    <path d="M48 0H0V48H48V0Z" fill="white" fillOpacity="0.01" />
    <path
      d="M34 5H8C6.34315 5 5 6.34315 5 8V34C5 35.6569 6.34315 37 8 37H34C35.6569 37 37 35.6569 37 34V8C37 6.34315 35.6569 5 34 5Z"
      fill={check ? "#2F88FF" : "none"}
      stroke="grey"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M43.9998 13.002V42.0001C43.9998 43.1046 43.1044 44.0001 41.9998 44.0001H13.0034"
      stroke="grey"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 20.4858L18.9997 26.0109L29 15.7192"
      stroke={check ? "white" : "grey"}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CheckIcon.defaultProps = {
  check: false,
};

import React, { useEffect, useState } from "react";
import { useApp } from "../../../AppProvider";
import { useInventoryData } from "../../../DataProviders/InventoryDataProvider";
import { Classifier } from "../../molecules/Classifier";
import { Modal } from "../../molecules/Modal";
import { Input } from "../../atoms/Input";
import { Button } from "../../atoms/Button";
import { UploadWidget } from "../../molecules/UploadWidget";
import { MAX_QUANTITY } from "../../../util/Constants";
import "./index.scss";

export const ItemForm = () => {
  const { openItemForm, setOpenItemForm, editItem, setEditItem } = useApp();
  const { save, remove, categoryOptions } = useInventoryData();
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [price, setPrice] = useState("");
  const [oldPrice, setOldPrice] = useState("");
  const [value, setValue] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [image, setImage] = useState("");
  const [id, setId] = useState("");
  const [bulk, setBulk] = useState(false);
  const [hide, setHide] = useState(false);
  const baseClass = "ItemForm";

  const quantityOptions = Array.from({ length: MAX_QUANTITY }, (_, i) => i + 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalCategory = category === "Add New" ? newCategory : category;
    const item = {
      id,
      name,
      category: finalCategory,
      description,
      price,
      oldPrice,
      value,
      tags,
      bulk,
      image,
      quantity,
      hide,
    };
    await save(item);
    handleCancel();
  };
  const handleCancel = () => {
    resetState();
    setOpenItemForm(false);
  };

  const handleDelete = async () => {
    await remove(id);
    handleCancel();
  };

  const clearState = () => {
    setName("");
    setCategory("");
    setNewCategory("");
    setDescription("");
    setPrice("");
    setOldPrice("");
    setValue("");
    setTags("");
    setBulk(false);
    setQuantity(1);
    setHide(false);
  };

  const resetState = () => {
    clearState();
    setImage("");
    setEditItem("");
  };

  useEffect(() => {
    if (categoryOptions[0]) {
      setCategory(categoryOptions[0]);
    }
  }, [categoryOptions, openItemForm]);

  useEffect(() => {
    if (editItem) {
      setName(editItem.name);
      setDescription(editItem.description);
      setValue(editItem.value);
      setPrice(editItem.price || "");
      setOldPrice(editItem.oldPrice || "");
      setCategory(editItem.category);
      setImage(editItem.image);
      setQuantity(editItem.quantity);
      setTags(editItem.tags || "");
      setBulk(editItem.bulk || false);
      setId(editItem.id);
      setHide(editItem.hide || false);
    } else {
      setId(new Date().valueOf().toString());
    }
  }, [editItem, openItemForm]);

  return (
    <Modal open={openItemForm}>
      <form className={baseClass} onSubmit={handleSubmit}>
        <div className={`${baseClass}Id`}>#{id}</div>
        <Input label="Name" value={name} setValue={setName} required />
        <Classifier
          toggle={category === "Add New"}
          label="Category"
          value={category}
          setValue={setCategory}
          newValue={newCategory}
          setNewValue={setNewCategory}
          options={[...categoryOptions, "Add New"]}
        />
        <div className={`${baseClass}Row1`}>
          <Input label="Value" value={value} setValue={setValue} />
          <Input
            Type="select"
            label="Quantity"
            value={quantity}
            setValue={setQuantity}
            options={quantityOptions}
          />
          <Input
            className={`${baseClass}Bulk`}
            label="Bulk"
            type="checkbox"
            checked={bulk}
            setValue={setBulk}
          />
        </div>
        <div className={`${baseClass}Row2`}>
          <Input label="Price" value={price} setValue={setPrice} />
          <Input label="Old Price" value={oldPrice} setValue={setOldPrice} />
        </div>
        <Input
          label="Description"
          value={description}
          setValue={setDescription}
          Type="textarea"
        />
        <div className={`${baseClass}Row3`}>
          <Input label="Tags" value={tags} setValue={setTags} />
          <Input
            className={`${baseClass}Bulk`}
            label="Hide"
            type="checkbox"
            checked={hide}
            setValue={setHide}
          />
        </div>
        <UploadWidget setImage={setImage} id={id} />
        <Button
          className={`${baseClass}Confirm Green`}
          type="submit"
          label="Confirm"
          disabled={!image}
        />
        <Button className="Yellow" label="Cancel" onClick={handleCancel} />
        <Button label="Clear" onClick={clearState} />
        {editItem && (
          <Button className="Red" label="Delete" onClick={handleDelete} />
        )}
      </form>
    </Modal>
  );
};

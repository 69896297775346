import React from "react";
import { Sort } from "../../molecules/Sort";
import "./index.scss";

export const ActionBar = () => {
  const baseClass = "ActionBar";

  return (
    <div className={baseClass}>
      <Sort />
    </div>
  );
};

import React, { useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";
import { gsap } from "gsap";
import { useApp } from "../../../AppProvider";
import { useDevice } from "../../../util/useDevice";
import { Card } from "../../molecules/Card";
import "./index.scss";

export const Cards = ({ items, enableCheck, reduceMotion }) => {
  const { root } = useApp();
  const { isDesktop } = useDevice();
  const [active, setActive] = useState("");
  const cards = useRef([]);
  const baseClass = "Cards";

  const handleCardClick = (id) => {
    if (!isDesktop) {
      if (active === id) {
        setActive("");
      } else {
        setActive(id);
      }
    }
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      if (!reduceMotion && items.length > 0) {
        gsap.from(cards.current, { duration: 0.5, scale: 0.3, stagger: 0.05 });
      }
    }, root);
    return () => ctx.revert();
  }, [items, reduceMotion, root]);

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(
        baseClass,
        { check: enableCheck },
        { reduceMotion },
      )}
    >
      {items.map((item, i) => (
        <Card
          ref={(ref) => (cards.current[i] = ref)}
          className={classNames({ check: enableCheck })}
          key={`${item.id}-${i}`}
          item={item}
          enableCheck={enableCheck}
          onClick={() => handleCardClick(item.id)}
          active={active === item.id}
        />
      ))}
    </div>
  );
};

import React from "react";
import "./index.scss";

export const Contact = () => {
  const baseClass = "Contact";

  return (
    <div className={baseClass}>
      <h1>Contact Us</h1>
      <div className={`${baseClass}Terms`}>
        <div className={`${baseClass}TermsHeading`}>
          Please review our following terms and conditions for setup and hire
        </div>
        <br />
        <div className={`${baseClass}TermsBody`}>
          As per our cancellation terms, the deposit is non-refundable and will
          be refunded after the completion of the event. <br />
          The equipment used is of high value and must not cause or allow
          permanent damage to the equipment, either intentionally or through
          lack of care or attention, including by other occupants or guests. If
          any individual causes or allows permanent damage to the equipment, the
          owner can ask to arrange to repair the damage or to pay for the costs
          of the repairs if they are done by the owner.
        </div>
      </div>
      <br />
      <h3>Still have questions</h3>
      <br />
      <div
        className={`${baseClass}Click`}
        onClick={() => {
          window.location.href = `tel:+61410382251`;
        }}
      >
        +61 410382251
      </div>
      <div
        className={`${baseClass}Click`}
        onClick={() => {
          window.location.href = "mailto:hello@thelittlelights.com.au";
        }}
      >
        hello@thelittlelights.com.au
      </div>
      <div>Sydney, Australia</div>
    </div>
  );
};

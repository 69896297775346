import { useMediaQuery } from "react-responsive";

export const useDevice = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 769px)" });

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { remove as removeData, set as setData } from "firebase/database";
import { get, setRef, setRefById } from "../util/firebase";

const SetDataContext = createContext(null);
export const useSetData = () => useContext(SetDataContext);
export const SetDataProvider = ({ children }) => {
  const [collection, setCollection] = useState([]);

  /* DATA CRUD Operations */

  const getCollection = useCallback(() => {
    get(setRef).then((data) => {
      if (data) setCollection(data);
    });
  }, []);

  const save = useCallback(
    async (id, set) => {
      await setData(setRefById(id), set);
      getCollection();
    },
    [getCollection],
  );

  const remove = useCallback(
    async (id) => {
      await removeData(setRefById(id));
      getCollection();
    },
    [getCollection],
  );

  useEffect(() => {
    getCollection();
  }, [getCollection]);

  return (
    <SetDataContext.Provider
      value={{
        collection,
        save,
        remove,
      }}
    >
      {children}
    </SetDataContext.Provider>
  );
};

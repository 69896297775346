import React, { useEffect, useRef } from "react";
import { Button } from "../../atoms/Button";
import "./index.scss";
import { CLOUD_NAME, DB_NAME, UPLOAD_PRESET } from "../../../util/Constants";

export const UploadWidget = ({ id, setImage }) => {
  const cRef = useRef();
  const widget = useRef();
  const baseClass = "UploadWidget";

  useEffect(() => {
    cRef.current = window.cloudinary;
    widget.current = cRef.current.createUploadWidget(
      {
        cloudName: CLOUD_NAME,
        uploadPreset: UPLOAD_PRESET,
        publicId: id,
        showUploadMoreButton: false,
        folder: DB_NAME,
        cropping: true,
        showSkipCropButton: false,
        croppingShowBackButton: true,
        croppingAspectRatio: 1,
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          const { eager, secure_url } = result.info;
          setImage(eager ? eager[0].secure_url : secure_url);
        } else {
          console.log(error);
        }
      },
    );
  }, [setImage, id]);

  return (
    <Button
      className={baseClass}
      label="Upload Image"
      onClick={() => widget.current.open()}
    />
  );
};

import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ButtonIcon } from "../../atoms/ButtonIcon";
import { Input } from "../../atoms/Input";
import { OrderIcon } from "../../atoms/Icons/icons";
import { useInventoryData } from "../../../DataProviders/InventoryDataProvider";
import { useInventory } from "../../../pages/Inventory/InventoryProvider";
import { SELECT_DEFAULT } from "../../../util/Constants";
import "./index.scss";

export const Sort = () => {
  const { orderItems, filterItems, categoryOptions } = useInventoryData();
  const { filterCategory } = useInventory();
  const [filterBy, setFilterBy] = useState(filterCategory || SELECT_DEFAULT);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderBy, setOrderBy] = useState(false);
  const baseClass = "Sort";

  const handleSort = (key) => {
    setFilterBy(key);
    setSearchParams({ category: key });
    filterItems(key);
  };
  const handleOrder = () => {
    orderItems();
    setOrderBy((prev) => !prev);
  };

  return (
    <div className={baseClass}>
      <Input
        Type="select"
        value={filterBy}
        setValue={handleSort}
        options={[...categoryOptions, SELECT_DEFAULT]}
      >
        <ButtonIcon
          className={`${baseClass}Sort`}
          onClick={() => {}}
          src="/icons/sort.svg"
          alt="sort"
        />
      </Input>
      <ButtonIcon
        className={`${baseClass}Order ${orderBy ? "Down" : "Up"}`}
        onClick={() => handleOrder()}
        src={<OrderIcon />}
        alt="order"
      />
    </div>
  );
};

import React, { useState } from "react";
import { Input } from "../../atoms/Input";
import { ButtonIcon } from "../../atoms/ButtonIcon";
import { useInventoryData } from "../../../DataProviders/InventoryDataProvider";
import "./index.scss";

export const Search = () => {
  const { searchItems } = useInventoryData();
  const [key, setKey] = useState("");
  const baseClass = "Search";

  const handleSubmit = (e) => {
    e.preventDefault();
    searchItems(key);
  };

  return (
    <form className={baseClass} onSubmit={handleSubmit}>
      <Input value={key} setValue={setKey} placeholder="Search" />
      <ButtonIcon
        type="submit"
        src="/icons/searchWhite.svg"
        alt="search"
        disabled={key.length > 0 && key.length < 3}
      />
    </form>
  );
};

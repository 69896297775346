import React, { useCallback, useEffect, useState } from "react";
import { useInventoryData } from "../../../DataProviders/InventoryDataProvider";
import { useSetData } from "../../../DataProviders/SetDataProvider";
import { useApp } from "../../../AppProvider";
import { Button } from "../../atoms/Button";
import { Input } from "../../atoms/Input";
import { Cards } from "../Cards";
import { Modal } from "../../molecules/Modal";
import { SELECT_DEFAULT } from "../../../util/Constants";
import "./index.scss";

export const SetForm = () => {
  const { allItems, categoryOptions } = useInventoryData();
  const { save, remove } = useSetData();
  const {
    checkedItems,
    editSet,
    openSetForm,
    setOpenSetForm,
    setCheckedItems,
  } = useApp();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("All");
  const baseClass = "SetForm";

  const getItems = useCallback(() => {
    let filteredItems;
    if (category === "All") {
      filteredItems = allItems;
    } else {
      filteredItems = allItems.filter((item) => item.category === category);
    }
    let items = [];
    filteredItems.forEach((item) => {
      if (item.bulk) {
        items.push(item);
      } else {
        for (let i = 0; i < item.quantity; i++) {
          items.push(item);
        }
      }
    });
    return items;
  }, [category, allItems]);

  const handleSubmit = (e) => {
    e.preventDefault();
    save(id, { id, name, price, description, items: checkedItems });
    handleCancel();
  };

  const handleCancel = () => {
    clearState();
    setOpenSetForm(false);
  };
  const handleDelete = async () => {
    await remove(id);
    handleCancel();
  };
  const clearState = () => {
    setName("");
    setPrice("");
    setDescription("");
    setCheckedItems([]);
  };

  useEffect(() => {
    if (editSet) {
      setName(editSet.name);
      setPrice(editSet.price);
      setCheckedItems(editSet.items);
      setDescription(editSet.description);
      setId(editSet.id);
    } else {
      setId(new Date().valueOf().toString());
    }
  }, [editSet, openSetForm, setCheckedItems]);

  return (
    <Modal open={openSetForm}>
      <form className={baseClass} onSubmit={handleSubmit}>
        <div className={`${baseClass}Id`}>#{id}</div>
        <div className={`${baseClass}Group1`}>
          <Input label="Name" value={name} setValue={setName} required />
          <div className={`${baseClass}Group3`}>
            <Input label="Price" value={price} setValue={setPrice} />
            <Input label="Count" value={checkedItems?.length} disabled />
          </div>
        </div>
        <Input
          label="Description"
          value={description}
          setValue={setDescription}
          Type="textarea"
        />
        <Input
          Type={"select"}
          label="Category"
          value={category}
          setValue={setCategory}
          options={[...categoryOptions, SELECT_DEFAULT]}
        />
        <Cards items={getItems()} enableCheck={true} reduceMotion={true} />
        <div className={`${baseClass}Group2`}>
          <Button className="Green" type="submit" label="Create" />
          <Button className="Yellow" label="Cancel" onClick={handleCancel} />
          {editSet && (
            <Button className="Red" label="Delete" onClick={handleDelete} />
          )}
        </div>
      </form>
    </Modal>
  );
};
